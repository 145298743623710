import Image from 'next/image'
import React from 'react'
import {LocatorStoryblok} from 'types/storyblok-types'
import {renderRichText, storyblokEditable} from '@storyblok/react'
import ChevronRight from '../../public/images/icon-chevron-right.svg'
import classNames from 'classnames'
import Link from 'next/link'
import linkTypeChecker from 'util/linkTypeChecker'

type LocatorContainerTypeProps = {
  blok: LocatorStoryblok
}
type LocatorTypeProps = {
  title: string
  text?: string
  cta_url: string
  cta_url_target: string
  cta_style: LocatorStoryblok['cta_style']
  image_url?: string
  image_alt?: string
  blok: LocatorStoryblok
  button_alignment: LocatorStoryblok['button_alignment']
}

export const Locator = ({
  title,
  text,
  cta_url,
  cta_url_target,
  cta_style,
  image_url,
  image_alt,
  blok,
  button_alignment,
}: LocatorTypeProps) => {
  const chevronClassnames = classNames(
    'flex items-center justify-center rounded-lg',
    {
      'bg-dats-s4': cta_style === 'blue',
      'bg-dats-s10': cta_style === 'gray',
    },
  )

  const selfAlignmentClass = classNames({
    'justify-self-center ': button_alignment === 'center',
  })
  return (
    <div className="grid">
      <Link
        href={cta_url}
        target={cta_url_target}
        className={`flex ${selfAlignmentClass} border-s9 min-h-[80px] w-full items-center justify-between gap-4 rounded-dats border bg-white px-5 py-4 no-underline lg:max-w-[460px]`}
        {...storyblokEditable(blok)}
      >
        <div className="flex items-center justify-start space-x-4">
          <div className="flex shrink-0 items-center justify-start">
            {image_url ? (
              <Image
                src={image_url}
                alt={image_alt ? image_alt : ``}
                width={30}
                height={30}
              />
            ) : null}
          </div>
          <div className="flex flex-col items-start justify-around">
            <p className="font-rubik text-lg font-medium leading-5 text-dats-s5">
              {title}
            </p>
            {text ? (
              <div
                id="richtext"
                className={`font-sans text-sm font-normal text-dats-s5 [overflow-wrap:anywhere]`}
                dangerouslySetInnerHTML={{__html: text}}
              />
            ) : null}
          </div>
        </div>
        <div className="shrink-0">
          <div className={chevronClassnames}>
            <Image
              src={ChevronRight}
              alt="chevron"
              width={35}
              height={35}
              unoptimized={true}
            />
          </div>
        </div>
      </Link>
    </div>
  )
}

const LocatorContainer = ({blok}: LocatorContainerTypeProps) => {
  return (
    <Locator
      title={blok.title}
      text={blok.text ? renderRichText(blok.text) : undefined}
      cta_url={linkTypeChecker(blok.cta_link)}
      cta_url_target={blok.cta_link?.target}
      cta_style={blok.cta_style}
      image_url={blok.image?.filename}
      blok={blok}
      button_alignment={blok.button_alignment}
    />
  )
}

export default LocatorContainer
